<template>
  <collapse-card
    v-bind="$attrs"
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Collaborators
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <!-- existing collaborators table starts -->
        <div class="flex mb-3">
          <icon-button
            class="mr-3 w-7.5 h-7.5"
            icon="icons/plus.svg"
            :active="isCollaborationActive && isUserCollaborationMaster"
            active-btn-class="bg-custom-green-1 bg-opacity-20"
            active-icon-class="text-custom-green-1"
            @click="setAddCollaboratorSectionVisibility(true)"
          />
          <icon-button
            class="w-7.5 h-7.5"
            icon="icons/x.svg"
            :active="isCollaborationActive && isUserCollaborationMaster && isDeleteCollaboratorsBtnActive"
            active-btn-class="bg-primary-red bg-opacity-20"
            active-icon-class="text-primary-red"
            @click="deleteCollaboratorsFromCollaboration()"
          />
        </div>
        <brand-assurance-table
          v-model="existingCollaboratorsData.tableData"
          root-element-class="mb-10"
        >
          <!-- show 1 empty row if table is empty -->
          <template
            v-if="existingCollaboratorsData.tableData.data.length === 0"
            #customTableBody
          >
            <tr>
              <td
                class="p-2 whitespace-nowrap text-center border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs"
              >
                    &nbsp;
              </td>
              <td
                v-for="(_, tdIndex) in existingCollaboratorsData.tableData.columns"
                :key="'cell' + tdIndex"
                class="p-2 whitespace-nowrap border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs"
              >
                    &nbsp;
              </td>
            </tr>
          </template>
        </brand-assurance-table>
        <!-- existing collaborators table ends -->
        <hr
          v-if="showAddCollaboratorSection"
          class="border-t border-custom-gray-8 mb-10"
        >
        <!-- add collaborator section starts -->
        <template v-if="showAddCollaboratorSection">
          <div class="flex items-center h-7 mb-5">
            <base-button
              variant="btn-primary"
              class="mr-4 py-1"
              text="Save"
              @click="addNewCollaboratorsToCollaboration()"
            />
            <base-button
              variant="btn-link"
              text="Cancel"
              @click="setAddCollaboratorSectionVisibility(false)"
            />
          </div>
          <div class="grid grid-cols-2 gap-x-8">
            <div>
              <collapse-card
                :model-value="true"
                :show-collapse-button="false"
                disable-collapse
                class="mb-8"
              >
                <template #title>
                  <span class="mr-2 text-sm font-medium">
                    Search Collaborators
                  </span>
                </template>
                <template #cardContent>
                  <div class="py-2 px-2">
                    <base-input
                      v-model="collaboratorName"
                      type="text"
                      container-class="mb-4 flex items-center"
                    />
                    <div class="flex items-center">
                      <base-button
                        variant="btn-primary"
                        :disabled="!collaboratorName"
                        :is-loading="isAllCollaboratorsListLoading"
                        class="mr-4 py-1 h-7 flex items-center"
                        text="Search"
                        @click="handleSearch()"
                      />
                      <base-button
                        variant="btn-link"
                        :is-loading="isAllCollaboratorsListLoading"
                        text="Clear"
                        @click="clearSearch()"
                      />
                    </div>
                  </div>
                </template>
              </collapse-card>
              <div class="flex flex-row-reverse justify-between mb-4">
                <base-button
                  variant="btn-primary"
                  :disabled="!isAddCollaboratorsBtnActive"
                  class="mr-4 py-1"
                  text="Add"
                  @click="addCollaborator()"
                />
                <base-pagination
                  v-if="allCollaboratorsData.pageCount && !isAllCollaboratorsListLoading"
                  :model-value="allCollaboratorsData.page"
                  :total-pages="allCollaboratorsData.pageCount"
                  display-limit="4"
                  @update:modelValue="handleAllCollaboratorsPageUpdate($event)"
                />
              </div>

              <div
                v-if="isAllCollaboratorsListLoading"
                class="w-full text-center my-32"
              >
                <base-svg
                  class="h-4 w-4 mr-1 text-primary-red inline-block"
                  src="icons/circleSpinner.svg"
                  tag="span"
                />
                Searching ...
              </div>
              <brand-assurance-table
                v-else
                v-model="allCollaboratorsData.tableData"
                root-element-class="mb-10"
              />
            </div>
            <div>
              <collapse-card
                :model-value="true"
                :show-collapse-button="false"
                disable-collapse
                class="mb-9"
              >
                <template #title>
                  <span class="mr-2 text-sm font-medium">
                    {{ loadedCollaboratorsList ? 'New' : 'Saved' }} Collaborators List
                  </span>
                </template>
                <template #cardContent>
                  <div class="py-2 px-2">
                    <base-input
                      v-if="loadedCollaboratorsList"
                      v-model="newCollaboratorsListName"
                      type="text"
                      container-class="mb-4 flex items-center"
                      placeholder="Enter List Name..."
                      label="List Name"
                      label-class="label-w-36"
                    />
                    <base-select
                      v-else
                      v-model="selectedSavedCollaboratorsListName"
                      class="flex items-center text-sm text-left mb-4"
                      placeholder="- Select Saved List -"
                      label="Saved List"
                      label-class="label-w-36"
                      :options="savedCollaboratorsTemplateListNames"
                      show-default-option
                      @dropdownOpened="fetchSavedCollaboratorLists()"
                    />
                    <div class="flex items-center">
                      <template
                        v-if="loadedCollaboratorsList"
                      >
                        <base-button
                          variant="btn-primary"
                          class="mr-4 py-1 h-7 flex items-center"
                          :is-loading="savingCollaboratorsTemplateList"
                          text="Save List"
                          @click="saveNewCollaboratorList()"
                        />
                        <base-button
                          v-if="!isNewCollaboratorList"
                          class="mr-4 py-1 h-7 flex items-center"
                          :is-loading="isCollaboratorListDeleting"
                          variant="btn-link"
                          text="Delete List"
                          @click="deleteSavedCollaboratorList()"
                        />
                        <base-button
                          class="mr-4 py-1 h-7 flex items-center"
                          variant="btn-link"
                          text="Cancel"
                          @click="handleSavedCollaboratorListCancel()"
                        />
                      </template>
                      <base-button
                        v-else
                        class="mr-4 py-1 h-7 flex items-center"
                        variant="btn-primary"
                        text="Create New"
                        @click="createNewCollaboratorList()"
                      />
                    </div>
                  </div>
                </template>
              </collapse-card>
              <icon-button
                class="w-7.5 h-7.5 mb-4"
                icon="icons/x.svg"
                :active="isNewCollaboratorsDeleteButtonActive"
                active-btn-class="bg-primary-red bg-opacity-20"
                active-icon-class="text-primary-red"
                @click="deleteNewCollaborators()"
              />
              <brand-assurance-table
                v-model="newCollaborators.tableData"
                root-element-class="mb-10"
              >
                <template
                  v-if="newCollaborators.tableData.data.length === 0"
                  #customTableBody
                >
                  <tr>
                    <td
                      class="p-2 whitespace-nowrap text-center border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs"
                    >
                    &nbsp;
                    </td>
                    <td
                      v-for="(_, tdIndex) in existingCollaboratorsData.tableData.columns"
                      :key="'cell' + tdIndex"
                      class="p-2 whitespace-nowrap border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs"
                    >
                    &nbsp;
                    </td>
                  </tr>
                </template>
              </brand-assurance-table>
            </div>
          </div>
        </template>
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent, ref, onMounted, watch, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import useCollaborationForm from './collaborationForm.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import {
    ERROR, SUCCESS, WARNING, ADD_ATLEAST_ONE_COLLABORATOR, ADD_ATLEAST_TWO_COLLABORATORS,
    COLLABORATOR_TEMPLATE_CREATE_FAILED, COLLABORATOR_TEMPLATE_CREATE_SUCCESS, COLLABORATOR_TEMPLATE_DELETE_FAILED,
    COLLABORATOR_TEMPLATE_DELETE_SUCCESS, COLLABORATOR_TEMPLATE_UPDATE_FAILED, COLLABORATOR_TEMPLATE_UPDATE_SUCCESS,
    COLLABORATOR_ALREADY_EXIST, ENTER_COLLABORATOR_LIST_NAME, NO_USERS_FOUND
} from '@/constants/alerts.js';

import { CREATIVE_ROLE, LEGAL_ROLE, BA_ADMIN_ROLE } from '@/constants/ba-roles.js';

export default {
    name: 'Collaborators',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    inheritAttrs: false,

    props: {
        collabId: {
            type: String,
            default: '',
            description: 'collaboarion id for fetching existing collaborators'
        }
    },

    setup (props, { emit }) {
        const store = useStore();
        const { collaborationForm, isCollaborationActive, isUserCollaborationMaster } = useCollaborationForm();
        const { notificationsStack } = useToastNotifications();
        const userId = computed(() => store.getters['auth/getUserId']);

        // collaborators table logic
        const existingCollaboratorsData = reactive({
            tableData: {
                columns: [
                    {
                        label: 'Collaborator Name',
                        key: 'userName'
                    },
                    {
                        label: 'Collaborator ID',
                        key: 'userId'
                    }
                ],
                data: collaborationForm.collaborators
            },
            page: 1,
            limit: 10,
            pageCount: 0
        });
        const selectedExistingCollaborators = computed(() => existingCollaboratorsData.tableData.data && existingCollaboratorsData.tableData.data.length ? existingCollaboratorsData.tableData.data.filter(tableObj => tableObj.selected) : []);
        const updateCollaboratorsInCollaborationForm = () => {
            collaborationForm.collaborators = existingCollaboratorsData.tableData.data.map(collaborator => {
                const { selected, ...restCollaborator } = collaborator;
                return restCollaborator;
            });
        };

        // search collaborator logic
        const collaboratorName = ref('');
        const handleSearch = async () => {
            allCollaboratorsData.page = 1;
            await fetchAllCollaboratorsList();
        };
        const clearSearch = async () => {
            collaboratorName.value = '';
            allCollaboratorsData.page = 1;
            await fetchAllCollaboratorsList();
        };

        // collaborator action logic
        const showAddCollaboratorSection = ref(false);
        const setAddCollaboratorSectionVisibility = (visibility) => {
            showAddCollaboratorSection.value = visibility;
        };
        const deleteCollaboratorsFromCollaboration = async () => {
            const selectedColloboratorsIds = selectedExistingCollaborators.value.map(collaborator => collaborator.userId);
            existingCollaboratorsData.tableData.data = existingCollaboratorsData.tableData.data.filter(collaborator => !selectedColloboratorsIds.includes(collaborator.userId));
            updateCollaboratorsInCollaborationForm();
        };
        const areCollaboratorsDuplicated = computed(() => existingCollaboratorsData.tableData.data.some(existingCollaborator => newCollaboratorsUserIds.value.includes(existingCollaborator.userId)));
        const addNewCollaboratorsToCollaboration = () => {
            if (existingCollaboratorsData.tableData.data.length === 0 && newCollaborators.tableData.data.length < 2) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: ADD_ATLEAST_TWO_COLLABORATORS
                });
            } else if (newCollaborators.tableData.data.length === 0) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: ADD_ATLEAST_ONE_COLLABORATOR
                });
            } else if (areCollaboratorsDuplicated.value) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: COLLABORATOR_ALREADY_EXIST
                });
            } else {
                existingCollaboratorsData.tableData.data = existingCollaboratorsData.tableData.data.concat(newCollaborators.tableData.data.map(newCollaborator => {
                    return {
                        ...newCollaborator,
                        userId: newCollaborator.collaborationUserId,
                        userName: newCollaborator.collaborationUserName,
                        selected: false
                    };
                }));
                resetSavedCollaboratorListSection();
                setAddCollaboratorSectionVisibility(false);
                updateCollaboratorsInCollaborationForm();
            }
        };
        const isDeleteCollaboratorsBtnActive = computed(() => selectedExistingCollaborators.value.length > 0);

        // saved collborator logic
        const selectedSavedCollaboratorsListName = ref('');
        const newCollaboratorsListName = ref('');
        const savedCollaboratorsTemplateLists = computed(() => {
            const collaboratorsList = store.getters['baCollaborations/getCollaborationTemplateList'];
            return collaboratorsList || [];
        });
        const savedCollaboratorsTemplateListNames = computed(() => savedCollaboratorsTemplateLists.value.length ? savedCollaboratorsTemplateLists.value.map(collabList => collabList.collaborationTemplateTitle) : []);
        const loadedCollaboratorsList = ref(null);
        const loadSavedCollaboratorList = () => {
            loadedCollaboratorsList.value = savedCollaboratorsTemplateLists.value.find(savedList => savedList.collaborationTemplateTitle === selectedSavedCollaboratorsListName.value);
            newCollaborators.tableData.data = loadedCollaboratorsList.value.collaborators;
            newCollaboratorsListName.value = selectedSavedCollaboratorsListName.value;
        };
        const savedCollaboratorsListLoading = ref(false);
        const fetchSavedCollaboratorLists = async () => {
            try {
                if (savedCollaboratorsListLoading.value || savedCollaboratorsTemplateLists.value.length) {
                    return;
                }
                savedCollaboratorsListLoading.value = true;
                await store.dispatch('baCollaborations/fetchCollaborationTemplate', { userName: userId.value });
            } catch (err) {
                console.error(err);
            } finally {
                savedCollaboratorsListLoading.value = false;
            }
        };
        watch(
            () => selectedSavedCollaboratorsListName.value,
            () => {
                if (selectedSavedCollaboratorsListName.value) {
                    loadSavedCollaboratorList();
                }
            }
        );
        const isNewCollaboratorList = ref(false);
        const createNewCollaboratorList = () => {
            isNewCollaboratorList.value = true;
            loadedCollaboratorsList.value = [];
            if (!newCollaborators.tableData.data) newCollaborators.tableData.data = [];
        };
        const isSaveNewCollaboratorsListBtnActive = computed(() => areNewCollaboratorsAdded.value && newCollaboratorsListName.value);
        const savingCollaboratorsTemplateList = ref(false);
        const saveNewCollaboratorList = async () => {
            try {
                if (newCollaborators.tableData.data.length === 0) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: ADD_ATLEAST_ONE_COLLABORATOR
                    });
                    return;
                }
                if (!newCollaboratorsListName.value) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: ENTER_COLLABORATOR_LIST_NAME
                    });
                    return;
                }
                if (savingCollaboratorsTemplateList.value) {
                    return;
                }
                savingCollaboratorsTemplateList.value = true;
                let bodyPayload;
                if (isNewCollaboratorList.value) {
                    bodyPayload = {
                        collaborationTemplateTitle: newCollaboratorsListName.value,
                        collaborationTemplateMaster: userId.value,
                        collaborators: newCollaborators.tableData.data.map(collaborator => {
                            return {
                                collaborationUserId: collaborator.collaborationUserId,
                                collaborationUserName: collaborator.collaborationUserName
                            };
                        })
                    };
                } else {
                    bodyPayload = {
                        ...loadedCollaboratorsList.value,
                        collaborationTemplateTitle: newCollaboratorsListName.value,
                        collaborators: newCollaborators.tableData.data.map(collaborator => {
                            const { selected, ...restCollaborator } = collaborator;
                            return restCollaborator;
                        })
                    };
                }
                await store.dispatch('baCollaborations/saveCollaborationTemplate', {
                    bodyPayload
                });
                // update collaborator list in table
                selectedSavedCollaboratorsListName.value = newCollaboratorsListName.value;
                loadSavedCollaboratorList();
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: isNewCollaboratorList.value ? COLLABORATOR_TEMPLATE_CREATE_SUCCESS : COLLABORATOR_TEMPLATE_UPDATE_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: isNewCollaboratorList.value ? COLLABORATOR_TEMPLATE_CREATE_FAILED : COLLABORATOR_TEMPLATE_UPDATE_FAILED
                });
            } finally {
                savingCollaboratorsTemplateList.value = false;
            }
        };
        const isCollaboratorListDeleting = ref(false);
        const deleteSavedCollaboratorList = async () => {
            if (isCollaboratorListDeleting.value) {
                return;
            };
            try {
                isCollaboratorListDeleting.value = true;
                await store.dispatch('baCollaborations/deleteCollaborationTemplate', {
                    bodyPayload: {
                        collaborationTemplateId: loadedCollaboratorsList.value.collaborationTemplateId
                    }
                });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: COLLABORATOR_TEMPLATE_DELETE_SUCCESS
                });
                resetSavedCollaboratorListSection();
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: COLLABORATOR_TEMPLATE_DELETE_FAILED
                });
            } finally {
                isCollaboratorListDeleting.value = false;
            }
        };
        const resetSavedCollaboratorListSection = () => {
            loadedCollaboratorsList.value = null;
            newCollaborators.tableData.data = [];
            newCollaboratorsListName.value = selectedSavedCollaboratorsListName.value = '';
            isNewCollaboratorList.value = false;
        };
        const handleSavedCollaboratorListCancel = () => {
            resetSavedCollaboratorListSection();
        };

        // all collaborators table logic
        const selectedCollaboratorsToBeAdded = computed(() => allCollaboratorsData.tableData.data && allCollaboratorsData.tableData.data.length ? allCollaboratorsData.tableData.data.filter(tableObj => tableObj.selected) : []);
        const allCollaboratorsData = reactive({
            tableData: {
                columns: [
                    {
                        label: 'Collaborator Name',
                        key: 'userName'
                    },
                    {
                        label: 'Collaborator ID',
                        key: 'id'
                    }
                ],
                data: []
            },
            page: 1,
            limit: 10,
            pageCount: 0
        });
        const areSelectedCollaboratorsAlreadyAdded = computed(() =>
            selectedCollaboratorsToBeAdded.value.length > 0 && newCollaboratorsUserIds.value.length > 0 &&
          selectedCollaboratorsToBeAdded.value.some(collaborator => newCollaboratorsUserIds.value.includes(collaborator.id))
        );
        const isSelectedCollaboratorsIncludeCurrentUser = computed(() =>
            selectedCollaboratorsToBeAdded.value.length > 0 &&
          selectedCollaboratorsToBeAdded.value.some(collaborator => collaborator.id === userId.value)
        );

        const isAddCollaboratorsBtnActive = computed(() => selectedCollaboratorsToBeAdded.value.length > 0);
        const fetchedAllCollaborators = computed(() => {
            const usersList = store.getters['users/getUsersList'];
            if (usersList?.data) {
                usersList.data = usersList.data.map(user => {
                    return {
                        ...user,
                        userName: `${user.firstname} ${user.lastname}`,
                        id: user.username
                    };
                });
            }
            return usersList;
        });
        const isAllCollaboratorsListLoading = ref(false);
        const fetchAllCollaboratorsList = async () => {
            if (isAllCollaboratorsListLoading.value) {
                return;
            }
            try {
                isAllCollaboratorsListLoading.value = true;
                await store.dispatch('users/fetchUsersList', {
                    params: {
                        page: allCollaboratorsData.page,
                        limit: allCollaboratorsData.limit,
                        searchScope: 'username,firstname,lastname',
                        searchKeyword: `${collaboratorName.value},${collaboratorName.value},${collaboratorName.value}`,
                        rolename: [CREATIVE_ROLE, LEGAL_ROLE, BA_ADMIN_ROLE].join(',')
                    }
                });
                updateAllCollaboratorsData();
            } catch (err) {
                console.error(err);
            } finally {
                isAllCollaboratorsListLoading.value = false;
            }
        };
        onMounted(async () => {
            await fetchAllCollaboratorsList();
        });
        const updateAllCollaboratorsData = () => {
            allCollaboratorsData.tableData.data = fetchedAllCollaborators.value.data;
            allCollaboratorsData.pageCount = fetchedAllCollaborators.value.pageCount;
            if (allCollaboratorsData.tableData.data.length === 0) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: NO_USERS_FOUND
                });
            }
        };
        const handleAllCollaboratorsPageUpdate = async (newPage) => {
            allCollaboratorsData.page = newPage;
            await fetchAllCollaboratorsList();
        };

        // new collaborators logic
        const newCollaborators = reactive({
            tableData: {
                columns: [
                    {
                        label: 'Selected Collaborator Name',
                        key: 'collaborationUserName'
                    },
                    {
                        label: 'Selected Collaborator ID',
                        key: 'collaborationUserId'
                    }
                ],
                data: []
            },
            page: 1,
            limit: 10,
            pageCount: 0
        });
        const newCollaboratorsUserIds = computed(() => newCollaborators.tableData.data.length > 0 ? newCollaborators.tableData.data.map(collaborator => collaborator.collaborationUserId) : []);
        const areNewCollaboratorsAdded = computed(() => newCollaborators.tableData.data.length > 0);
        const addCollaborator = () => {
            if (isSelectedCollaboratorsIncludeCurrentUser.value) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: 'Cannot add yourself as a collaborator'
                });
                return;
            };
            if (areSelectedCollaboratorsAlreadyAdded.value) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: COLLABORATOR_ALREADY_EXIST
                });
                return;
            };
            newCollaborators.tableData.data = newCollaborators.tableData.data.concat(selectedCollaboratorsToBeAdded.value.map(collaborator => {
                return {
                    collaborationUserId: collaborator.id,
                    collaborationUserName: collaborator.userName,
                    selected: false
                };
            }));
            allCollaboratorsData.tableData.data.forEach(tableObj => {
                tableObj.selected = false;
            });
        };
        const selectedNewCollaborators = computed(() => newCollaborators.tableData.data.length > 0 ? newCollaborators.tableData.data.filter(tableObj => tableObj.selected) : []);
        const isNewCollaboratorsDeleteButtonActive = computed(() => selectedNewCollaborators.value.length > 0);
        const deleteNewCollaborators = () => {
            newCollaborators.tableData.data = newCollaborators.tableData.data.filter(tableObj => !tableObj.selected);
        };

        return {
            // collaborators table
            existingCollaboratorsData,
            // search collaborator
            collaboratorName,
            handleSearch,
            isAllCollaboratorsListLoading,
            clearSearch,
            // collaborator action
            showAddCollaboratorSection,
            setAddCollaboratorSectionVisibility,
            deleteCollaboratorsFromCollaboration,
            addNewCollaboratorsToCollaboration,
            isDeleteCollaboratorsBtnActive,
            // saved collborator logic
            selectedSavedCollaboratorsListName,
            newCollaboratorsListName,
            savedCollaboratorsTemplateLists,
            savedCollaboratorsTemplateListNames,
            fetchSavedCollaboratorLists,
            loadedCollaboratorsList,
            createNewCollaboratorList,
            isNewCollaboratorList,
            isSaveNewCollaboratorsListBtnActive,
            saveNewCollaboratorList,
            savingCollaboratorsTemplateList,
            isCollaboratorListDeleting,
            deleteSavedCollaboratorList,
            handleSavedCollaboratorListCancel,
            // all collaborators table
            allCollaboratorsData,
            isAddCollaboratorsBtnActive,
            handleAllCollaboratorsPageUpdate,
            // new collaborators
            newCollaborators,
            addCollaborator,
            isNewCollaboratorsDeleteButtonActive,
            areNewCollaboratorsAdded,
            deleteNewCollaborators,
            isCollaborationActive,
            isUserCollaborationMaster
        };
    }

};
</script>
